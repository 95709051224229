import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MainSectionBlog from "../../components/blog/mainSection.js";

import "../../styles/blog.scss";
import BlogClanky from "../../components/blog/blogClanky";
import ContactUs from "../../components/contactUs";

export const pageQuery = graphql`
  query($slug: String) {
    allWpCategory(filter: { slug: { nin: ["vsetky", "pripadove-studie"] } }) {
      nodes {
        name
        slug
        count
      }
    }
    wpCategory(slug: { eq: $slug }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

const BlogPageCategory = (pageQuery) => (
  <Layout pageName="blog-main">
    <SEO
      title="Blog"
      description="Sledujeme pre vás najnovšie trendy a nasledujeme ich. Nenechajte si ujsť naše tipy, rady a dlhoročné skúsenosti, ktoré vám pomôžu pri tvorbe vášho webu."
    />
    <MainSectionBlog kategorie={pageQuery.data.allWpCategory.nodes} />
    <BlogClanky clanky={pageQuery.data.wpCategory.posts.nodes} />
    <ContactUs
      text={
        <>
          Neviete, ktorá služba bude najlepšia pre vás?
          <br /> Poradíme vám!
        </>
      }
      button="Poraďte sa s nami hneď"
    />
  </Layout>
);

export default BlogPageCategory;
